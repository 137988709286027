<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div
      v-if="employees.length"
      class="accordion"
      role="tablist"
    >
      <draggable
        animation="200"
        ghost-class="ghost-sort"
        :list="employees"
        @change="changedSorting"
      >
        <div
          v-for="employee in employees"
          :key="employee.id"
          class="accordion__item "
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="MenuIcon"
                      class="mr-2"
                    />
                    <div class="content-text-wrap">
                      {{ employee.name }}<br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else>
      Пока нет ни одной акции
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import Employee from '@/api/http/models/employee/Employee'
import { BOverlay } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    Draggable,
  },
  data() {
    return {
      showLoading: true,
      employees: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Employee.getAll(this.currentSite.slug)

    if (response.data) {
      this.employees = response.data
      this.showLoading = false
    }
  },
  methods: {
    async changedSorting() {
      const employees = this.employees.map((employee, index) => ({
        id: employee.id,
        order: index + 1,
      }))

      const response = await Employee.sort(this.currentSite.slug, {
        employees,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Сотрудники отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
